import axios from 'axios';
import { serverURL } from 'variables/ServerURL'


export default function axiosConfig(baseUrl=serverURL) {
    const instance = axios.create({ baseURL: baseUrl });
    (function () {
        let token = sessionStorage.getItem('token');
        if (token) {
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
            delete instance.defaults.headers.common['Authorization'];
        }

        let AppId = sessionStorage.getItem('AppId');
        if (AppId) {
            instance.defaults.headers.common['AppId'] = AppId;
        } else {
            delete instance.defaults.headers.common['AppId'];
        }

    })();
    return instance;
}

