import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

//import Custom Components
import SigninComponent from './SigninComponent'
import LoginDetails from './BoardGrade'


import { SwitchTransition, CSSTransition } from "react-transition-group";

// custom style
import useStyles from "assets/jss/components/views/signupStyle";


const modes = ["out-in", "in-out"];

const Signup = (props) => {

    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['1', '2'];
    const [mode, setMode] = useState("out-in");
    const [state, setState] = useState(true);
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState("")

    useEffect(() => {
        if (props.userInfo.userId) {
            setUserId(props.userInfo.userId);
            setActiveStep(1);
        }
    }, [props.userInfo])





    const handleNext = (userId = "") => {
        if (userId !== "") {
            setUserId(userId);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setState(state => !state)
    };




    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <SigninComponent
                    handleNext={handleNext}
                    handleLoading={setLoading}
                    textColor={props.textColor}
                    userInfo={props.userInfo}
                />
            case 1:
                return <LoginDetails
                    handleNext={handleNext}
                    handleLoading={setLoading}
                    userId={userId}
                />
            default:
                return 'Unknown step';
        }
    }


    return (

        <React.Fragment >
            <SwitchTransition mode={mode}>
                <CSSTransition
                    key={activeStep}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade"
                >
                    {getStepContent(activeStep)}

                </CSSTransition>
            </SwitchTransition>
        </React.Fragment >

    );
}


Signup.defaultProps = {
    onSignUp: () => { },
    isLoginAfterSignUp: false,
}

Signup.propTypes = {
    onSignUp: PropTypes.func,
    isLoginAfterSignUp: PropTypes.bool,
}

export default Signup;
