import React, { Suspense, lazy, useState, useEffect } from "react";
// @material-ui/core
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
// core components
import Dialog from "components/CustomComponents/Dialog/Dialog";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";

// Views
import Instruction from 'components/Views/Instruction/Instruction'
import GoCoderInstruction from "../Instruction/GoCoderInstruction";
import Quest from 'components/Views/Quest/Quest'

//commonfunctions
import { clevertapEvents } from "util/commonfunctions";

//axios
import axiosConfig from "util/axios";

//Redux
import { useSelector, useDispatch } from 'react-redux'
import * as questActions from 'store/actions/index'

// img and icon
import QuestLogo from "assets/img/icons/Questlogoheader_white.png";
import AddIcon from '@material-ui/icons/Add';
import CropFreeIcon from '@material-ui/icons/CropFree';

//variables
import { NexoolURL, LastUrl } from "variables/ServerURL";

// styles
import styles from "assets/jss/components/views/homeStyle";

import { serverDjangoURL } from "variables/ServerURL";

const useStyles = makeStyles(styles);

const HomeWeb = lazy(() => import('components/Views/Home/HomeWeb'));
const HomeMobile = lazy(() => import('components/Views/Home/HomeMobile'));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const Main = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  let app_user_type = localStorage.getItem('user_type')
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const questLoading = useSelector(state => state.quest.loading)
  const quest = useSelector(state => state.quest.current_quest)
  const infoLoading = useSelector(state => state.user.loading)
  const userInfo = useSelector(state => state.user.userinfo)
  const goCoderInfo = useSelector(state => state.user.goCoderInfo)
  const usertype = useSelector(state => state.user.user_type)
  const userquest = useSelector(state => state.quest.userquest)
  const contentLoading = useSelector(state => state.content.loading)
  const questContent = useSelector(state => state.content.contentlist)
  const apptype = useSelector(state => state.appconfigs.apptype)

  const [dialogState, setDialogState] = useState(false);
  const [goCoderDialog, setGoCoderDialog] = useState(false);
  const [questDialog, setQuestDialog] = React.useState(false);
  const [newUserAlert, setNewUserAlert] = useState(false);

  /*-----------------------handle Close Instruction Dialog--------------------*/
  const CloseInstructionDialog = () => {
    setDialogState(false);
  };

  /*-----------------------handle Close Instruction Dialog--------------------*/
  const openInstructionDialog = (mission) => {
    dispatch(questActions.setCurrentMission(mission)) //set selected mission

    if(mission.missionName === "GO_CODERZ"){
      props.history.push('/task')
      return
    }

    if (mission.hasOnlineSession) {
      if (mission.missionName !== undefined && mission.missionName !== null && mission.missionName.toUpperCase() === "CODING") {
        let username = userInfo.userName;
        let institutionId = userInfo.institutionId;
        if (username.startsWith('SNVV@'))
          username = username.split('@')[1].concat(`.ict@${institutionId}.questplus.in`);
        else
          username = username.includes("@") ? username.slice(0, username.indexOf('@')).concat(`.ict@${institutionId}.questplus.in`) : username;
        let buff = new Buffer(username);
        let base64data = buff.toString('base64');
        window.open(`https://kms.ict360.com/ict/student-login/207/${base64data}`, "_")
      }
      else
        props.history.push('/missions-schedules')
    }

    else
      setDialogState(true);
  };

  /*-----------------------handle Close Dialog--------------------*/
  const onStartMission = () => {
    setDialogState(false);
    props.history.push('/task')
  }

  /*-----------------------handle Quest Dialog Open --------------------*/
  const openQuestDialog = (apptype) => {
    if (apptype === 'Spectrum' || app_user_type === 'Spectrum')
      setQuestDialog(true);
    else
      props.history.push('/products')
  };
  /*-----------------------handle Quest Dialog Close --------------------*/
  const closeQuestDialog = () => {
    setQuestDialog(false);
  };
  /*----------------------- Change Quest Data --------------------*/
  const onChangeQuest = (CurrentQuest) => {
    setQuestDialog(false);
    dispatch(questActions.setCurrentQuest(CurrentQuest))
    dispatch(questActions.initContentList(CurrentQuest.questId))
  }

  //-----------------------Handel Buy Quest---------------------
  const onBuyQuest = async (CurrentQuest) => {
    const cartItem = {
      "userId": userInfo.userId,
      "productId": CurrentQuest.productId,
    }
    //clevertap
    const eventData = JSON.stringify({
      'UserId': userInfo.userId,
      'ProductId': CurrentQuest.productId,
      'ProductName': CurrentQuest.questName
    });

    clevertapEvents('PurchaseInitiate', eventData);

    await dispatch(questActions.addToCart(cartItem));
    props.history.push('/cart');
  }


  /*----------------------- Push the App Registrations lead data on CRM tool --------------------*/
  useEffect(() => {

    if (questLoading === false && infoLoading === false) {
      if (usertype === 'NewUser') {
        setNewUserAlert(true)
        pushDataToCRM();
        dispatch(questActions.setUserType('ExistingUser'))
      }
    }
  }, [questLoading, infoLoading])

  useEffect(()=>{
    if(goCoderInfo === undefined || Object.keys(goCoderInfo).length === 0 ){
      setGoCoderDialog(true)
    }
  },[goCoderInfo,userInfo])

  const pushDataToCRM = () => {
    const params = {
      phone: userInfo.userName,
      email: '',
      name: userInfo.firstName,
      agent_centre: 'op',
      campaign: 'App Registration',
      source: 'Live Lead',
      product_name: quest.questName,
      extra: ""
    };

    //axios.get('https://lms.obliquepyramid.com/user_tracking/insert_leads/no_validation.php', { params })
    axiosConfig().get('/QuestUser/SetCRMData', {
      params: {
        phone: userInfo.userName,
        name: userInfo.firstName,
        product_name: quest.questName
      }
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  /*----------------------- Zoom -------------------*/
  useEffect(() => {
    if (typeof window.Android !== 'undefined' && typeof window.Android.setWebViewZoom !== 'undefined') {
      console.log("Calling Zoom");
      window.Android.setWebViewZoom(false);
    } else if (typeof window.flutter_inappwebview !== 'undefined' && typeof window.flutter_inappwebview.setWebViewZoom !== 'undefined') {
      window.flutter_inappwebview.callHandler('setWebViewZoom', false, '');
    }
  }, [])

  /*----------------------- Open URL --------------------*/
  const onOpenURL = async () => {
    //setLoadingSpinner(true)
    await axiosConfig().get('/QuestUser/GetNexoolToken', {
      params: {
        UserId: userInfo.userId,
        QuestId: quest.questId
      }
    }).then(response => {
      if (typeof window.Android !== 'undefined') {
        if (typeof window.Android.setWebViewZoom !== 'undefined') {
          console.log("Calling Zoom");
          window.Android.setWebViewZoom(true);
        }
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      } else if (typeof openNewUrl !== 'undefined') {
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      } else if (typeof window.flutter_inappwebview !== 'undefined') {
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      } else {
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      }
    })
  }

  // handle click  tests
  const onClickTest = () => {
    props.history.push('/assessment-tests')
  }

  const onClickGoCoderActionInstruction=async(type)=>{
    const formData = new FormData();
    formData.append('user_id', userInfo.userId)
    formData.append('Grade', "-")
    formData.append('enabled', 1)

    formData.append('isActive', true)
    if(type ==='yes'){
      formData.append('opted', 1)
    }else{
      formData.append('opted', 0)
    }


    await axiosConfig(serverDjangoURL).post('api/gocoderz_prompt/', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(response=>{
        dispatch(questActions.setGoCoderPrompt(response.data))
        setGoCoderDialog(false)
    }).catch((error) => {
        if (error.response.data){
          setGoCoderDialog(false)
          console.log(error.response.data?.error)
        }
           
    });

  } 

  return (
    <React.Fragment>

      {/*-----Dialog----- */}
      <Dialog open={goCoderDialog} maxWidth={"md"} TransitionComponent={Transition}  className='goCoderModal'>
        <GoCoderInstruction
          quest={quest}
          onClose={()=>setGoCoderDialog(false)}
          onClickAction={onClickGoCoderActionInstruction} />
      </Dialog>
      <Dialog open={dialogState} maxWidth={"xs"} TransitionComponent={Transition} >
        <Instruction
          quest={quest}
          onBuy={onBuyQuest}
          onClose={CloseInstructionDialog}
          onStart={onStartMission} />
      </Dialog>

      {/*-----Dialog Quest----- */}
      <Dialog open={questDialog} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
        <Quest
          logo={QuestLogo}
          data={userquest}
          activeData={quest}
          onChange={onChangeQuest}
          onClose={closeQuestDialog}
        />
      </Dialog>


      {/* <AlertDialog
        open={newUserAlert}
        type="info"
        ContentTextStyle={{ color: 'black', marginBottom: 0 }}
        title={'Welcome to Singhania Quest+ Learning App.'}
        titleStyle={{ paddingBottom: 0 }}
        ContentText={'Our Quest+ Education Counsellor will be calling you soon to guide you about the App. Please continue exploring.'}
        cancelable={() => { }}
        action={[
          { text: 'OK', onPress: () => setNewUserAlert(false) }
        ]}
      /> */}


      {userquest.questList.length === 0
        ? <div className={classes.warningAlert}>
          <Alert severity="warning" elevation={20}>
            <AlertTitle>Quest assign</AlertTitle>
            Your next year's quest will be assigned soon.
          </Alert>
        </div>
        : null
      }

      <Suspense fallback={<div>Loading...</div>}>
        {sm
          ? <HomeMobile
            quest={quest}
            questLoading={questLoading}
            infoLoading={questLoading}
            contentLoading={contentLoading}
            questContent={questContent}
            userquest={userquest}
            apptype={apptype}
            appUserType={app_user_type}

            onOpenURL={onOpenURL}
            onBuy={onBuyQuest}
            onClickMission={openInstructionDialog}
            onChangeQuest={openQuestDialog}
            onClickTest={onClickTest}
          /> // mobile 
          : <HomeWeb
            quest={quest}
            questLoading={questLoading}
            infoLoading={questLoading}
            contentLoading={contentLoading}
            questContent={questContent}
            userquest={userquest}
            apptype={apptype}
            appUserType={app_user_type}
            

            onOpenURL={onOpenURL}
            onBuy={onBuyQuest}
            onClickMission={openInstructionDialog}
            onChangeQuest={openQuestDialog}
            onClickTest={onClickTest}
          /> //web
        }
      </Suspense>
    </React.Fragment>

  );
}


export default Main;
