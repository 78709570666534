import * as actionTypes from './actionTypes';
import axiosConfig from "util/axios";

export const setProductBanner = (bannerLists) => {
    return {
        type: actionTypes.SET_PRODUCT_BANNER,
        payload: bannerLists
    }
}

export const fetchProductBanner = (ProductId) => {
    return async dispatch => {
        axiosConfig().get('/QuestContent/GetProductBanner', {
            params: {
                ProductId: ProductId
            }
        }).then(response => {
            dispatch(setProductBanner(response.data.bannerLists))
        }).catch(error => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }

}