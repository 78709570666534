import React from 'react';
import clsx from 'clsx';
import { TransitionGroup, CSSTransition } from "react-transition-group";
// import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { Route, Switch, withRouter } from "react-router-dom";
// import { createBrowserHistory } from 'history';

// import style
import withStyles from '@material-ui/core/styles/withStyles';
import styles from "assets/jss/components/layouts/layoutStyle.js";
import "assets/css/pageLayouts/page.css";
// Comman Component
import SignIn from '../Views/SignIn/SigninContainer'
//Redux
import { connect } from 'react-redux'
import * as questActions from 'store/actions/index'
// import Drawer from '../CustomComponents/Drawer/Drawer';

//Routes Components
import Routes from '../../variables/Routes'
const SwitchRoutes = (props) => (
    <Switch location={props.location}>
        {Routes.map((prop, key) => {
            if (prop.submenu.length)
                return prop.submenu.map(submenu => <Route
                    path={submenu.layout + submenu.path}
                    component={submenu.component}
                    key={key + submenu.name} />)
            return <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key + prop.name}
            />
        })}
    </Switch>
);


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
            mobileOpen: false,
            prevDepth: this.getPathDepth(this.props.location)
        }
    }

    componentWillReceiveProps = () => {
        this.setState({ prevDepth: this.getPathDepth(this.props.location) })
        // set Platform
        if (this.props.history.location.pathname === "/") {
            let ios = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform) || navigator.userAgent.includes("Mac") || navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad");
            if (ios) this.props.setPlatform("IOS");
            else this.props.setPlatform("")

            const params = new URLSearchParams(window.location.search)           
            const AppType = params.get('AppType') || localStorage.getItem('AppType') ;
            localStorage.setItem('AppType', AppType);
            this.props.setAppType(AppType)

        }
    }

    componentWillMount() {
        let ios = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) || navigator.userAgent.includes("Mac") || navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad");
        // if (typeof window.Android === 'undefined' && !ios) {
        //     this.props.history.push(`/notfound`)
        // } else {
            if (!sessionStorage.getItem('token')) {
                const params = new URLSearchParams(window.location.search)
                this.props.history.push(`/${params ? "?" + params.toString() : ""}`)
        //    }
         }


    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    getPathDepth = (location) => {
        let pathArr = location.pathname.split("/")
        pathArr = pathArr.filter(n => n !== "")
        return pathArr.length
    }

    render() {
        const { classes, location } = this.props;
        // const timeout = { enter: 800, exit: 400 }
        const currentKey = location.pathname.split("/")[1] || "/";
        return (

            <div className={classes.wrapper}>
                <div className={classes.mainPanel} >

                    <div className={clsx(classes.content, { [classes.contentShift]: this.state.mobileOpen, })}>

                        <TransitionGroup component='div' className={classes.container}>
                            <CSSTransition
                                key={currentKey}
                                addEndListener={(node, done) => { node.addEventListener("transitionend", done, false); }}
                                classNames="fade" >
                                <div className={this.getPathDepth(location) - this.state.prevDepth >= 0 ? "left" : "right"}>
                                    {/* Routes */}
                                    <Route exact path="/" component={() => <SignIn />} />

                                    <SwitchRoutes location={location} />
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                    </div>
                </div>
            </div>

        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setPlatform: (platform) => dispatch(questActions.setPlatform(platform)),
        setAppType: (apptype) => dispatch(questActions.setAppType(apptype)),
    }
}



export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(Home)));